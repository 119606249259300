body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "@progress/kendo-font-icons/dist/index.css";

@font-face {
  font-family: "Pretendard-R";
  src: url("./font/Pretendard-Regular.woff2") format("woff2");
  font-display: auto;
}

@font-face {
  font-family: "Pretendard-B";
  src: url("./font/Pretendard-Bold.woff2") format("woff2");
  font-display: auto;
}



.totalpage .k-tilelayout-item-header.k-card-header{
  font-family: "Pretendard-B";
  background:transparent;
  color: black;
  font-weight: bold!important;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.projectpage .k-tilelayout-item-header.k-card-header{
  font-family: "Pretendard-B";
  background-color: #34568B;
  color:#F1F2F5 !important;
  font-weight: bold!important;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.projectpage h5{
  color:#F1F2F5 !important;
}
.totalpage .k-card-header {
  border: none!important;
}

.body{
  font-family: "Pretendard-R";
}

.richimag img{
  width: 100%;
  object-fit: contain; 
  max-height: 200px;
}
.richimag2 img{
  width: 100%;
  object-fit: contain; 
  max-height: 600px;

}


.loadingContainer svg{    
  position:absolute;
  top:calc(50% - 30px);
  left:calc(50% - 30px);}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.minicombo .k-svg-icon {
  width: 12px;
  height: 12px;
}

/* TextField 텍스트 색상 변경 */
.daterange .MuiInputBase-input.MuiOutlinedInput-input {
  color: #F1F2F5!important;
}

.daterangecolor .MuiInputBase-input.MuiOutlinedInput-input {
  color: black!important;
}



.daterange2 .MuiInputBase-input{  padding: 7px !important;
  height: 15px !important;
  color: black !important;
  font-size: 14px !important;
  margin-top: 0;}

  .daterange .MuiInputBase-input{  padding: 7px !important;
    height: 15px !important;
    font-size: 14px !important;
    margin-top: 0;}

/* TextField 테두리 색상 변경 */
.MuiOutlinedInput-notchedOutline {
  border-color: #F1F2F5 !important; /* 테두리 색상을 하얀색으로 설정 */
}

.daterange .MuiInputBase-formControl {
  min-width: 220px!important;
  font-size: 12px;
}

.daterange2 {
  min-width: 200px!important;
  margin-right: 8px!important;
}

.MuiStack-root  {
  padding-top: 0px !important;
  
}


.side-navigation-panel-select-option-text{
  font-size: 14px!important;
}

.metric {
  display: flex;
  align-items: center;
  gap: 10px; /* 간격 조정 */
}

.value {
  margin-right: 5px; /* 값과 지표 사이 간격 조정 */
}

.indicator {
  font-weight: bold;
  
}

.negative {
  color: #176FE8; /* 음수일 때 색상 */
}

.positive {
  color: #F8443E; /* 양수일 때 색상 */
}

.number {
  position: absolute;
  color: black; /* 숫자 색상 */
  font-size: 12px; /* 글자 크기 */
  z-index: 999999;
  font-weight: 600;
  /* 글자 위치 조정이 필요할 수 있습니다 */
}


.number .MuiInputBase-formControl {
  /* min-width: 200px!important; */
  font-size: 10px;
}

.selcetSafety  .MuiSelect-select {
  font-size: 10px!important;
  height: 14px!important;
  padding-right: 10px !important;

}


  
/* .pyramid td,.pyramid th {
  padding: .2em .2em;
  vertical-align: middle;
}
   */
.pyramid td {
text-align: center;
padding: .2em .2em;
vertical-align: middle;
}

.pyramid  td{
  border-bottom: 1px solid snow;
  background: #fff;
  font-weight: 600;
  font-size: 0.7rem;
  color:snow;
}

 .pyramid  th {
  border-bottom: 1px solid #fff;
  background: #fff;
  font-weight: 500;
  font-size: 0.7rem;
}


.selection .MuiInputBase-root {
  margin-top: 2px!important;
}
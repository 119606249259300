.quality-ir-performance-item-box {
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  border-right: none;
  position: relative;
}

.quality-ir-performance-item {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.quality-ir-performance-item.header-cell {
  background-color: #f9fafb;
}

.quality-ir-performance-item:nth-last-child(-n + 4) {
  border-bottom: none;
}

.quality-ir-performance-item:nth-child(4n + 1) {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.quality-ir-performance-item:nth-child(2) {
  border-top-left-radius: 4px;
}

.quality-ir-performance-item:nth-child(5) {
  border-top-right-radius: 4px;
}

.quality-ir-performance-item:nth-child(22) {
  border-bottom-left-radius: 4px;
}

.quality-ir-performance-item:nth-child(25) {
  border-bottom-right-radius: 4px;
}

.quality-bold-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.quality-normal-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  font-family: Inter;
  font-size: 14px;
}

.quality-ir-performance-filter-box {
  position: absolute;
  top: -36px;
  right: 0;
  z-index: 999;
}

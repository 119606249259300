.quality-detail-inspection-status-title {
  font-family: Inter;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 700;

  margin-bottom: 5px !important;
}

.quality-detail-inspection-cons-table,
.quality-detail-inspection-supervision-table {
  border: 2px solid rgba(224, 224, 224, 1);
  border-right: none;
  border-bottom: none;
}

.quality-detail-inspection-cons-table-item {
  border-right: 1px dotted rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.quality-detail-inspection-cons-table-item.border-delete {
  border: none;
}

.quality-detail-inspection-cons-table-item.double-line {
  border-bottom: 2px double rgba(224, 224, 224, 1);
}

.quality-detail-inspection-cons-table-item.bottom-bold {
  border: none;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
}
.quality-detail-inspection-cons-table-item.right-bold {
  border-right: 2px solid rgba(224, 224, 224, 1);
}

.quality-detail-inspection-table-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;

  width: 100%;
  height: 100%;
  text-align: center;

  color: var(--gray-900, var(--Gray-900, #0d0d0d));

  /* text-sm/font-normal */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.quality-detail-inspection-table-text.bold {
  font-weight: 700;
}

.bg-color-light-gray {
  background-color: var(--Gray-100, #f2f2f2);
}

.bg-color-gray {
  background-color: var(--Gray-400, #969696);
}

.bg-color-light-blue {
  background: #e2f3ff;
}

.bg-color-light-yellow {
  background: #fff8e0;
}

.quality-detail-inspection-status-pie-chart-tooltip-text {
  color: var(--gray-900, var(--Gray-900, #0d0d0d));

  /* text-sm/font-normal */
  font-family: Inter;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 700;
}

.quality-page-main {
  font-family: Inter !important;
}

.quality-page-change-btn-box {
  display: flex;
  flex-direction: row;

  color: #fff;

  z-index: 999999;
}

.quality-page-change-btn {
  display: flex;
  width: 118px;
  padding: var(--p-1, 4px) var(--p-2, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--None, 0px);

  border: 1px solid var(--Gray-200, #d1d1d1);
  background: var(--Foundation-Netural-0, #fff);

  text-align: center;

  cursor: pointer;

  color: var(--Foundation-Netural-100, #3d3d3d);

  /* body M */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  transition: all 0.3s ease-out;
}

.quality-page-change-btn:nth-child(1) {
  border-radius: var(--2x, 8px) var(--None, 0px) var(--None, 0px) var(--2x, 8px);
}
.quality-page-change-btn:nth-child(2) {
  border-radius: var(--None, 0px) var(--2x, 8px) var(--2x, 8px) var(--None, 0px);
}

.quality-page-change-btn.active,
.quality-page-change-btn:hover {
  color: var(--Foundation-Netural-0, #fff);
  background: var(--Blue-samsung-bx, #004098);
}

.quality-main-ncr {
  position: relative;
}

.quality-filter-btn-box {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 10px;
  top: -36px;
  right: 0;
}

.quality-filter-area {
  padding: 10px;
  position: absolute;
  top: 29px;
  right: 0;

  background-color: #fff;

  z-index: 999;

  overflow-y: auto;
}

.quality-filter-area .k-treeview-leaf:focus,
.quality-filter-area .k-treeview-leaf.k-focus,
quality-detail-filter-drop-down-list:focus {
  box-shadow: none;
}

.quality-detail-filter-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.quality-detail-filter-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.quality-detail-filter-text {
  font-family: Inter;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 700;
}

.quality-detail-filter-drop-down-list {
  width: 180px;
  float: right;

  height: 30px;
  font-family: Inter;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 400;

  background: var(--Gray-50, #f9fafb);
}

.quality-detail-filter-drop-down-list.short {
  width: 100px;
}

.quality-detail-filter-drop-down-list.short2 {
  width: 140px;
}

.quality-tile-body .k-tilelayout-item-header.k-card-header {
  background-color: #fff;
  border-bottom: none;
}

.quality-tile-body .k-tilelayout-item-header.k-card-header h5 {
  font-family: Inter;
  font-weight: 900 !important;
}

.quality-tile-body .k-tilelayout-item-body.k-card-body {
  padding: 0px 16px 16px 16px;
}

.point-legend-table {
  height: 100%;
  width: 100%;
}

.point-legend-point-label {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.point-legend-table-top-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.point-legend-table-top-box div:nth-child(2) {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.point-legend-table-bottom-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.point-legend-table-bottom-box div {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.quality-detail-top-container,
.quality-main-top-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 0px 10px;
  height: 32px;
}

/* .quality-main-top-container {
  justify-content: space-between;
} */

.indicator-k-table-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.indicator-k-table-title {
  color: rgba(0, 0, 0, 0.87);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.k-grid-md .k-table-th > .k-cell-inner > .k-link {
  font-weight: 600 !important;
  justify-content: center !important;
  font-size: 0.7rem;
  text-align: center;
}

.k-grid-table {
  font-size: 0.7rem;
}

.k-grid-norecords .k-table-td {
  text-align: center;
  padding-block: 2px;
}
.qtable .k-grid-header col:nth-of-type(1) {
  width: 50px;
}
.qtable .k-grid-table col:nth-of-type(1) {
  width: 50px;
}

.qtable .k-grid-header col:nth-of-type(2) {
  width: 60px;
}
.qtable .k-grid-table col:nth-of-type(2) {
  width: 60px;
}

.qtable .k-grid-header col:nth-of-type(3) {
  width: 70px;
}
.qtable .k-grid-table col:nth-of-type(3) {
  width: 70px;
}

.qtable .k-grid-header col:nth-of-type(4) {
  width: 70px;
}
.qtable .k-grid-table col:nth-of-type(4) {
  width: 70px;
}

.qtable .k-grid-header col:nth-of-type(5) {
  width: 60px;
}
.qtable .k-grid-table col:nth-of-type(5) {
  width: 60px;
}

.qtable .k-grid-header col:nth-of-type(6) {
  width: 70px;
}
.qtable .k-grid-table col:nth-of-type(6) {
  width: 70px;
}

.qtable .k-grid-header col:nth-of-type(7) {
  width: 70px;
}
.qtable .k-grid-table col:nth-of-type(7) {
  width: 70px;
}

.qtable .k-grid-header col:nth-of-type(8) {
  width: 70px;
}
.qtable .k-grid-table col:nth-of-type(8) {
  width: 70px;
}
.qtable .k-grid-header col:nth-of-type(9) {
  width: 60px;
}
.qtable .k-grid-table col:nth-of-type(9) {
  width: 60px;
}
.qtable .k-grid-header col:nth-of-type(10) {
  width: 50px;
}
.qtable .k-grid-table col:nth-of-type(10) {
  width: 50px;
}
.qtable .k-grid-header col:nth-of-type(11) {
  width: 60px;
}
.qtable .k-grid-table col:nth-of-type(11) {
  width: 60px;
}
.qtable .k-grid-header col:nth-of-type(12) {
  width: 50px;
}
.qtable .k-grid-table col:nth-of-type(12) {
  width: 50px;
}
.qtable .k-grid-header col:nth-of-type(13) {
  width: 50px;
}
.qtable .k-grid-table col:nth-of-type(13) {
  width: 50px;
}
.qtable .k-grid-header col:nth-of-type(14) {
  width: 60px;
}
.qtable .k-grid-table col:nth-of-type(14) {
  width: 60px;
}
.qtable .k-grid-header col:nth-of-type(15) {
  width: 60px;
}
.qtable .k-grid-table col:nth-of-type(15) {
  width: 60px;
}
.qtable .k-grid-header col:nth-of-type(16) {
  width: 60px;
}
.qtable .k-grid-table col:nth-of-type(16) {
  width: 60px;
}

.MuiInputBase-input .MuiOutlinedInput-input {
  padding: 10px !important;
}
/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px !important;
  height: 15px !important;
  color: black !important;
  font-size: 14px !important;
  margin-top: 0;
} */
.css-ymqfn8-MuiStack-root {
  padding-top: 0 !important;
}
.css-ymqfn8-MuiStack-root > .MuiTextField-root {
  min-width: 230px !important;
}
.css-1emixs5-MuiPaper-root-MuiPickersPopper-paper {
  color: black !important;
}
.css-omvvz4-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day {
  color: black !important;
}
.css-9te0qn-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day {
  color: black !important;
}
.css-16vzlpz-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.css-16vzlpz-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: white;
}

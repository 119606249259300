

.nohover {
    font: normal normal normal 0.8rem Pretendard;
    text-align: right;
    background-color: white; 
  }
  
  .nohover:hover {
    background-color: white; 
  }
  
  .loadingContainer svg{    
    position:absolute;
    top:calc(50% - 30px);
    left:calc(50% - 30px);}
  
    .tab-container{
      font-size: 6px;
    }
  
    .flex {
      display: flex;
    }
  
    .relative {
      position: relative;
    }
  
    .min-h-screen {
      min-height: 100vh;
    }
    
  .min-w-full {
    min-width: 100%;
  }
  
  .h-screen {
    height: 100vh;
  }
  
  .bg-gray-200 {
    --bg-opacity: 1;
    background-color: #062653;
    /* background-color: rgba(237, 242, 247, var(--bg-opacity)); */
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .flex-1 {
    flex: 1 1 0%;
  }
  
  .overflow-hidden {
    overflow: hidden;
  }
  
  .sm\:flex-row {
    flex-direction: row;
  }
  
  .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .absolute {
    position: absolute;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .text-center {
    text-align: center;
  }
  
  .bottom-0 {
    bottom: 0;
  }
  
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option{
    color: #F5F5F5!important;
    font-size: 1.2rem!important;
    opacity: 0.6;
  
  }
  
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected{
    background-color: #062653!important;
    color: #F5F5F5!important;
    opacity: 1!important;
  
  
  }
  
  .side-navigation-panel *, .side-navigation-panel::after, .side-navigation-panel::before{
    border: none!important;
  }
  
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option .side-navigation-panel-select-inner-option-wrap .side-navigation-panel-select-inner-option-text{
    color: #F5F5F5!important;
    margin-left: 0!important;
    margin-right: 0!important;
  }
  
  
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option:hover {
    background-color: transparent!important; /* 배경색 변경 없음 */
    color:red!important;
    opacity: 0.7!important;
  }
  
  .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option:hover{
    background-color: transparent!important; /* 배경색 변경 없음 */
    color:red!important;
    opacity: 0.7!important;
  }
  
  
  
  
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option-selected {
    background-color: transparent!important;      color: #F5F5F5!important;
    opacity: 1!important;
  }
  
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option .side-navigation-panel-select-inner-option-wrap .side-navigation-panel-select-inner-option-text{
    opacity: 1!important;
  
  }
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option-selected .side-navigation-panel-select-inner-option-wrap .side-navigation-panel-select-inner-option-text{
    color: #F5F5F5!important;
    opacity: 1!important;
  }

  @media (min-width: 1024px) {
    .btn-menu {
      display: none;
      position: relative;
    }
  }

  
  .block {
    display: block;
  }

  .hidden {
    display: none;
  }
  
  .side-navigation-panel-select-option-text{
    font-size: 1.2rem!important;
    color:#F5F5F5!important;
    opacity: 1!important;

  }
.ncr-rate-quarter-table-body {
  color: var(--Gray-500, #737373);

  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  line-height: 18px; /* 128.571% */
  font-weight: 500;

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ncr-rate-quarter-table-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.ncr-rate-quarter-table-item.bold-text {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}

.ncr-rate-quarter-table-item.quality-table-border-bottom {
  border-bottom: 1px solid #d1d1d1;
}

.ncr-rate-quarter-table-item.bold-text.item-top {
  background: var(--Gray-50, #f9fafb);
  border-bottom: 1px solid #d1d1d1;
}


.k-grid-md .k-table-th > .k-cell-inner > .k-link {
    font-weight: 600!important;
    justify-content: center!important;
    font-size: 0.8rem;
}

.ptable .k-grid-header col:nth-of-type(1){
    width: 200px
  }
  .ptable .k-grid-table col:nth-of-type(1){
    width: 200px
  }
  
  .ptable .k-grid-header col:nth-of-type(2){
    width: 12%;
  }
  .ptable .k-grid-table col:nth-of-type(2){
    width: 12%;
  
  }
  
  .ptable .k-grid-header col:nth-of-type(3){
    width: 12%
  }
  .ptable .k-grid-table col:nth-of-type(3){
    width: 12%
  }
  
  .ptable .k-grid-header col:nth-of-type(4){
    width: 12%
  }
  .ptable .k-grid-table col:nth-of-type(4){
    width: 12%
  }
  
  .ptable .k-grid-header col:nth-of-type(5){
    width: 12%
  }
  .ptable .k-grid-table col:nth-of-type(5){
    width: 12%
  }
  
  .ptable .k-grid-header col:nth-of-type(6){
    width: 12%
  }
  .ptable .k-grid-table col:nth-of-type(6){
    width: 12%
  }
  
  .ptable .k-grid-header col:nth-of-type(7){
    width: 12%
  }
  .ptable .k-grid-table col:nth-of-type(7){
    width: 12%
  }
  
  .ptable .k-grid-header col:nth-of-type(8){
    width: 12%
  }
  .ptable .k-grid-table col:nth-of-type(8){
    width: 12%
  }
   
  
.atable2 .k-grid-header col:nth-of-type(1){
  width: 600px
}
.atable2 .k-grid-table col:nth-of-type(1){
  width: 600px
}

.atable2 .k-grid-header col:nth-of-type(2){
  width: 12%;
}
.atable2 .k-grid-table col:nth-of-type(2){
  width: 12%;

}

.atable2 .k-grid-header col:nth-of-type(3){
  width: 10%
}
.atable2 .k-grid-table col:nth-of-type(3){
  width: 10%
}

.atable2 .k-grid-header col:nth-of-type(4){
  width: 10%
}
.atable2 .k-grid-table col:nth-of-type(4){
  width: 10%
}

.atable3 .k-grid-header col:nth-of-type(1){
  width: 600px
}
.atable3 .k-grid-table col:nth-of-type(1){
  width: 600px
}

.atable3 .k-grid-header col:nth-of-type(2){
  width: 15%;
}
.atable3 .k-grid-table col:nth-of-type(2){
  width: 15%;

}

.atable3 .k-grid-header col:nth-of-type(3){
  width: 10%
}
.atable3 .k-grid-table col:nth-of-type(3){
  width: 10%
}

.atable3 .k-grid-header col:nth-of-type(4){
  width: 10%
}
.atable3 .k-grid-table col:nth-of-type(4){
  width: 10%
}

.atable3 .k-grid-header col:nth-of-type(4){
  width: 10%
}
.atable3 .k-grid-table col:nth-of-type(4){
  width: 10%
}


/* .atable th {
  font-size: 0.75rem;
} */

.atable .k-grid-header .k-header {
  font-size: 14px; /* 헤더 폰트 크기 */
}

.atable .k-grid-content td {
  font-size: 14px; /* 행 폰트 크기 */
}
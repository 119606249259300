.quality-inspection-progress-status-box {
  position: relative;
}

.quality-inspection-progress-status-filter-box {
  position: absolute;
  top: -36px;
  right: 0;
  z-index: 999;
}

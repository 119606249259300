.target-status-border-box {
  border-radius: var(--1x, 4px);
  border: 1px solid var(--Foundation-Netural-20, #e0e0e0);
}

.target-status-contents-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--Foundation-Netural-100, #3d3d3d);
  /* body L - bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.target-status-contents-detail-body {
  margin: 0px 10px;
  border-bottom: 1px solid #e0e0e0;
}

.target-status-contents-detail-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2px;
}

.target-status-contents-detail-title {
  color: var(--Foundation-Netural-90, #525252);
  /* body S */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.target-status-contents-detail-value {
  display: flex;
  gap: 5px;
  align-items: flex-end;

  /* body S */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.target-status-contents-detail-value div:nth-child(1) {
  color: var(--Foundation-Netural-100, #3d3d3d);
  text-align: right;
  /* body M - bold */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
}

.inspection-approval-card-body {
  color: var(--Gray-500, #737373);

  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  line-height: 18px; /* 128.571% */

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.inspection-approval-card {
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #d1d1d1;
}

.inspection-approval-card.card-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  background: var(--Gray-50, #f9fafb);
}

.inspection-approval-card.card-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
}

.inspection-approval-card.bold-text {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}

.inspection-approval-card.small-text {
  font-size: 12px;
  font-weight: 500;
}

.inspection-approval-card.large-text {
  font-size: 24px;
}

.inspection-approval-table-body-2 {
  color: var(--Gray-500, #737373);

  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  line-height: 18px; /* 128.571% */
}

.inspection-approval-table-total {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.inspection-approval-table-item {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inspection-approval-table-bottom-border {
  border-bottom: 1px solid #d1d1d1;
}

.inspection-approval-table-bottom-border:nth-child(2) {
  background: var(--Gray-50, #f9fafb);
}

.inspection-approval-table-item.bold-text {
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}
